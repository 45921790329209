.app {
  font-family: sans-serif;
  text-align: center;
  /* min-height: 100vh; */
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.tick {
  font-size: 30px;
}

.tick-label {
  font-size: 0.5em;
  color: #e3e3e3;
}

@media screen and (max-width: 420px) {
  .app.widget {
    .item1 {
      grid-column-start: 4;
      grid-column-end: 6;
      grid-row-start: 1;
    }
    
    .item-clock {
      grid-column-start: 1;
      grid-column-end: 9;
      grid-row-start: 1;
      margin-top: 150px;
    }
  }
  .item.item1 {
    font-size: 30px;
  }
  .tick-group {
    margin: 5px;
  }
  .tick-flip {
    font-size: 30px;
  }
  .tick-label.tick-text {
    font-size: 20px;
  }
}

/* iPad */
@media screen and (min-width: 420px) and (max-width: 1024px) {
  .item.item1 {
    font-size: 60px;
  }
  .tick-group {
    margin: 15px;
  }
  .tick-flip {
    font-size: 60px;
  }
  .tick-label.tick-text {
    font-size: 40px;
  }
}

/* @media screen and (min-width: 350px) and (max-width: 419px) {
  .tick {
    font-size: 26px;
  }

  .item1 {
    font-size: 30px !important;
  }
}
 */

.grid-container {
  display: grid;
  gap: 10px;
  /* background-color: #282c34; */
  min-height: 100vh;
}

.item {
  /* font-size: 50px; */
  margin-top: 50px;
  color: #222222;
}

.item1 {
  grid-column-start: 4;
  grid-column-end: 6;
}

.item-clock {
  grid-column-start: 1;
  grid-column-end: 9;
}