.tick {
  font-size: 120px;
  white-space: nowrap;
  font-family: arial,sans-serif;
}

.tick-flip, .tick-text-inline {
  font-size: 100px;
}

.tick-label {
  margin-top: 1em;
  font-size: 65px;
}

.tick-char {
  width: 40px;
}

.tick-text-inline {
  display: inline-block;
  text-align: center;
  min-width: 20px;
}

.tick-group {
  margin: 0 .5em;
  text-align: center;
}

body {
   background-color: #fff !important; 
}

.tick-text-inline {
   color: #595d63 !important; 
}

.tick-label {
   color: #e3e3e3 !important;
}

.tick-flip-panel {
   color: #fff !important; 
}

.tick-flip {
   font-family: arial,sans-serif !important; 
}

.tick-flip-panel-text-wrapper {
   line-height: 1.45 !important; 
}

.tick-flip-panel {
   background-color: #3c3e3c !important; 
}

.tick-flip {
   border-radius:0.12em !important; 
}